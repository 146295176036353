import React from 'react'
import styled from '@emotion/styled'

import Layout from 'components/layouts/signIn'
import { TYPOGRAPHY_18 } from 'theme/theme'

const Text = styled.div`
  ${TYPOGRAPHY_18}

  color: #a0a5b3;

  @media (max-width: 767px) {
    text-align: center;
  }
`

export default function Custom404 () {
  return (
    <Layout sTitle="Not Found 404" title="Not Found" description="Not Found" path="/404" noCB={true}>
      <div>
        <Text>Sorry, this page does not exist.</Text>
      </div>
    </Layout>
  )
}
